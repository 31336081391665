import React from "react";

const MessageSkeleton = () => {
  return (
    <div className="flex items-center space-x-2 animate-pulse p-3 bg-blue-100 dark:bg-blue-900 w-full ml-auto max-w-sm rounded-2xl rounded-br-none">
      <div className="flex-shrink-0 h-8 w-8 bg-blue-300 dark:bg-blue-700 rounded-full"></div>
      <div className="flex-1 space-y-2">
        <div className="h-4 bg-blue-300 dark:bg-blue-700 rounded w-3/4"></div>
        <div className="h-4 bg-blue-300 dark:bg-blue-700 rounded w-1/2"></div>
      </div>
    </div>
  );
};

export default MessageSkeleton;
