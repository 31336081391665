import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import appReducer from "./slices/app";
import authReducer from "./slices/auth";
import tagReducer from "./slices/tag";
import responseReducer from "./slices/response";
import userReducer from "./slices/user";
import chatReducer from "./slices/chat";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
};

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  tag: tagReducer,
  response: responseReducer,
  user: userReducer,
  chat: chatReducer,
});

export { rootPersistConfig, rootReducer };
