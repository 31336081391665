import React, { useEffect, useRef, useState } from "react";
import { PaperPlaneTilt, X } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import { ToggleCannedMediaModal } from "../redux/slices/app";
import { sendDirectMessage } from "../socket/socketConnection";

const CannedMediaModal = () => {
  const { open, content, url } = useSelector(
    (state) => state.app.modals.cannedMedia
  );

  const { currentParticipant } = useSelector((state) => state.chat);
  const { user } = useSelector((state) => state.user);

  const [inputValue, setInputValue] = useState(content);
  const modal = useRef(null);
  const dispatch = useDispatch();

  // Sync inputValue with content whenever content changes
  useEffect(() => {
    setInputValue(content || "");
  }, [content]);

  // Close modal if the Esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!open || keyCode !== 27) return;
      dispatch(
        ToggleCannedMediaModal({
          open: false,
          content: null,
          url: null,
        })
      );
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [open, dispatch]);

  const handleSendMessage = async () => {
    const data = {
      participant: currentParticipant,
      message: {
        author: user._id,
        type: "Media",
        content: content,
        media: [
          {
            type: "image",
            url,
          },
        ],
      },
    };

    sendDirectMessage(data);

    // close modal window after sending message
    dispatch(
      ToggleCannedMediaModal({
        open: false,
        content: null,
        url: null,
      })
    );
  };

  return (
    <div
      className={`fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 ${
        open ? "block" : "hidden"
      }`}
    >
      <div
        ref={modal}
        className="md:px-17.5 w-full max-w-142.5 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-8"
      >
        <div className="flex flex-row items-center space-x-2 justify-between mb-8">
          <div className="text-md font-medium text-black dark:text-white">
            Send Media
          </div>

          <button
            onClick={() => {
              dispatch(
                ToggleCannedMediaModal({
                  open: false,
                  content: null,
                  url: null,
                })
              );
            }}
          >
            <X size={24} />
          </button>
        </div>

        <img
          src={url}
          alt={inputValue}
          className="w-full h-60 object-cover object-center rounded-lg"
        />

        <div className="flex flex-row items-center space-x-2 justify-between mt-4">
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSendMessage(e);
            }}
            className="border rounded-lg hover:border-primary outline-none w-full p-2 border-stroke dark:border-strokedark bg-transparent dark:bg-form-input"
            placeholder="Type your message..."
          />

          <button
            type="button"
            onClick={handleSendMessage}
            className="p-2.5 border border-primary flex items-center justify-center rounded-lg bg-primary hover:bg-opacity-90 text-white"
          >
            <PaperPlaneTilt size={20} weight="bold" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CannedMediaModal;
