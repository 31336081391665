import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useDispatch } from "react-redux";
import { SetCurrentConversation, UpdateUserInfo } from "../redux/slices/chat";

export default function Layout() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.localStorage.setItem('current-conversation', null);
    dispatch(SetCurrentConversation(null));
    dispatch(UpdateUserInfo(false));
  }, []);
  return (
    <div className="h-[calc(100vh)] overflow-hidden sm:h-[calc(100vh)]">
      <div className="h-full rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark xl:flex">
        {/* Sidebar */}
        <Sidebar />

        <Outlet />
      </div>
    </div>
  );
}
