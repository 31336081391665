import React, { useEffect, useRef, useState } from "react";
import {
  DocumentMsg,
  GiphyMessage,
  MediaMsg,
  TextMsg,
  VoiceMsg,
} from "../../components/Messages";
import MsgSeparator from "../../components/MsgSeparator";
import {
  getDirectChatHistory,
  markConversationReadById,
} from "../../socket/socketConnection";
import { useSelector } from "react-redux";

import { format } from "date-fns";

import dateFormat, { masks } from "dateformat";
import MessageSkeleton from "../../components/Messages/Skeleton";
import { ArrowDown, CaretDown } from "@phosphor-icons/react";

export default function Messages() {
  const containerRef = useRef(null);

  const { user } = useSelector((state) => state.user);
  const [MSG_LIST, setMSG_LIST] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set loading to false after 3 seconds
    const timer = setTimeout(() => setLoading(false), 400);

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  const {
    currentParticipant,
    currentMessages,
    sendingMsg,
    userInfoOpen
  } = useSelector((state) => state.chat);

  function formatTime(dateString) {
    masks.hammerTime = "HH:MM";
    return dateFormat(dateString, "hammerTime");
  }

  useEffect(() => {
    setMSG_LIST(
      currentMessages
        ? currentMessages.map((msg) => {
            const incoming = msg.author === user._id ? false : true;
            const authorName = incoming ? "Support Team" : user.name;
            const content = msg?.content;
            const timestamp = formatTime(msg.date);
            const type = msg?.type;
            const id = msg?._id;
            const edited = msg?.edited;
            const active = msg?.active;
            const sender = msg?.sender;

            switch (msg?.type) {
              case "Text":
                return {
                  id,
                  incoming,
                  content,
                  timestamp,
                  authorName,
                  type,
                  date: msg?.date,
                  edited,
                  active,
                  sender,
                };

              case "Document":
                return {
                  id,
                  incoming,
                  content,
                  timestamp,
                  authorName,
                  type,
                  document: msg.document,
                  date: msg?.date,
                  edited,
                  active,
                  sender,
                };

              case "Media":
                return {
                  id,
                  incoming,
                  content,
                  timestamp,
                  authorName,
                  type,
                  media: msg.media,
                  date: msg?.date,
                  edited,
                  active,
                  sender,
                };

              case "Giphy":
                return {
                  id,
                  incoming,
                  content,
                  timestamp,
                  authorName,
                  type,
                  giphy: msg.giphyUrl,
                  date: msg?.date,
                  edited,
                  active,
                  sender,
                };

              case "Audio":
                return {
                  id,
                  incoming,
                  content,
                  timestamp,
                  authorName,
                  type,
                  audioUrl: msg?.audioUrl,
                  date: msg?.date,
                  edited,
                  active,
                  sender,
                };

              default:
                break;
            }
          })
        : []
    );
  }, [currentMessages]);

  const scrollToBottom = () => {
    // Scroll to the bottom of the container on mount
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the container on mount
    scrollToBottom();
  }, [MSG_LIST, sendingMsg]);

  // Mark conversation as read

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => {
      getDirectChatHistory({
        participant: currentParticipant,
      });
    }, 250);

    // Set loading to false after 4 seconds
    const loadingTimer = setTimeout(() => setLoading(false), 400);

    // Cleanup timer on component unmount
    return () => {
      clearTimeout(timer);
      clearTimeout(loadingTimer);
    };
  }, [currentParticipant]);

  return loading ? (
    <div className="no-scrollbar flex flex-row flex-grow text-center justify-center items-center max-h-full space-y-3.5 overflow-auto px-6 py-7.5 dark:bg-boxdark-2">
      <div className="flex flex-row items-center space-x-2">
        <svg
          className="mr-2 inline h-4 w-4 animate-spin text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C3.58 0 0 3.58 0 8h4z"
          ></path>
        </svg>
        <span className="text-xl text-gray font-bold">Loading...</span>
      </div>
    </div>
  ) : MSG_LIST.length > 0 ? (
    <div
      ref={containerRef}
      className="py-10 no-scrollbar flex-grow max-h-full space-y-3.5 overflow-auto px-6 dark:bg-boxdark-2 relative"
    >
      <button
        onClick={() => {
          scrollToBottom();
        }}
        className={`z-999999 fixed shadow-lg bottom-25 ${userInfoOpen ?  'right-[420px]' : 'right-6'} rounded-full bg-boxdark border dark:border-strokedark p-1`}
      >
        <ArrowDown size={24} />
      </button>
      {MSG_LIST.map((message, index) => {
        const isNewDay =
          index === 0 ||
          format(new Date(MSG_LIST[index - 1].date), "yyyy-MM-dd") !==
            format(new Date(message.date), "yyyy-MM-dd");

        return (
          message && (
            <React.Fragment key={message.id}>
              {isNewDay && <MsgSeparator date={message?.date} />}
              {(() => {
                switch (message?.type) {
                  case "Text":
                    return (
                      <TextMsg
                        id={message.id}
                        author={message.author}
                        content={message.content}
                        incoming={message.incoming}
                        timestamp={message.timestamp}
                        edited={message.edited}
                        active={message.active}
                        sender={message.sender}
                      />
                    );

                  case "Giphy":
                    return (
                      <GiphyMessage
                        id={message.id}
                        author={message.author}
                        content={message.content}
                        incoming={message.incoming}
                        timestamp={message.timestamp}
                        giphy={message.giphy}
                        edited={message.edited}
                        active={message.active}
                        sender={message.sender}
                      />
                    );

                  case "Document":
                    return (
                      <DocumentMsg
                        id={message.id}
                        author={message.author}
                        content={message.content}
                        incoming={message.incoming}
                        timestamp={message.timestamp}
                        documentFile={message.document}
                        edited={message.edited}
                        active={message.active}
                        sender={message.sender}
                      />
                    );

                  case "Audio":
                    return (
                      <VoiceMsg
                        id={message.id}
                        author={message.author}
                        content={message.content}
                        incoming={message.incoming}
                        timestamp={message.timestamp}
                        document={message.document}
                        audioUrl={message.audioUrl}
                        edited={message.edited}
                        active={message.active}
                        sender={message.sender}
                      />
                    );
                  case "Media":
                    console.log(message, "This is media msg");
                    return (
                      <MediaMsg
                        id={message.id}
                        incoming={message.incoming}
                        author={message.author}
                        timestamp={message.timestamp}
                        media={message.media}
                        caption={message.content}
                        edited={message.edited}
                        active={message.active}
                        sender={message.sender}
                      />
                    );

                  default:
                    break;
                }
              })()}
            </React.Fragment>
          )
        );
      })}

      {sendingMsg && <MessageSkeleton />}
    </div>
  ) : (
    <div className="flex-grow flex items-center justify-center">
      <div className="text-xl text-gray font-bold">
        Como podemos ajudar você hoje?
      </div>
    </div>
  );
}
