import { Tag, X } from "@phosphor-icons/react";
import React, { useEffect, useRef } from "react";

export default function DeleteTag({ open, handleClose }) {
  const modal = useRef(null);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      handleClose();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div
      className={`fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 ${
        open ? "block" : "hidden"
      }`}
    >
      <div
        ref={modal}
        className="md:px-17.5 w-full max-w-142.5 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-8 space-y-6"
      >
        <div className="flex flex-row items-center justify-between">
          <div className="text-black dark:text-white">Are you sure to delete tag ?</div>

          <button onClick={handleClose}>
            <X size={20} />
          </button>
        </div>

        <div className="text-warning font-semibold">
          Warning: This tag will be removed from all 48 users
        </div>

        <div>
          <div className="space-y-2 mb-8 text-start">
            <div className="border border-primary text-primary dark:border-strokedark px-4 py-1.5 rounded-full flex flex-row items-center justify-center">
              New Customer
              <button className="ml-2">
                <Tag />
              </button>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between space-x-4">
            <button
              onClick={handleClose}
              className="w-full cursor-pointer rounded-lg border border-danger bg-transparent p-4 text-danger transition hover:bg-opacity-90"
            >
              Cancel
            </button>
            <button className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
