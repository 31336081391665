import React from "react";
import { Trash } from "@phosphor-icons/react";

export default function Deleted({ incoming }) {
  return (
    <div className="relative">
      {incoming ? (
        <div className={`max-w-125 w-fit`}>
          <div className="relative mb-2.5 rounded-2xl rounded-tl-none bg-gray px-5 py-3 dark:bg-boxdark-2 space-y-2">
            <div className="flex flex-row items-center space-x-2 text-slate-400">
              <p>Message Deleted</p>
              <Trash size={24} />
            </div>
          </div>
        </div>
      ) : (
        <div className="ml-auto max-w-125 w-fit">
          <div className="relative mb-2.5 rounded-2xl rounded-br-none dark:bg-boxdark-2 px-5 py-3 space-y-2">
            <div className="flex flex-row items-center justify-end space-x-2 text-slate-400">
              <p>Message Deleted</p>
              <Trash size={24} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
