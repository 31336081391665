import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  error: null,
  tags: [],
};

const slice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    fetchTagsSuccess(state, action) {
      state.tags = action.payload;
    },
    updateTagSuccess(state, action) {
      state.tags = state.tags.map((tag) => {
        if (tag._id === action.payload._id) {
          return action.payload;
        } else {
          return tag;
        }
      });
    },
    deleteTagSuccess(state, action) {
      state.tags = state.tags.filter((tag) => tag._id !== action.payload.id);
    },
    addTagSuccess(state, action) {
      state.tags.unshift(action.payload);
    },
  },
});

export default slice.reducer;

const {
  setError,
  setLoading,
  addTagSuccess,
  deleteTagSuccess,
  fetchTagsSuccess,
  updateTagSuccess,
} = slice.actions;

// CreateTag
export function CreateTag(tagData, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.post("/employee/tag", tagData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(addTagSuccess(response?.data?.data?.tag));
      toast.success("Tag created successfully!");
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to create tag.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}

// Get Tags
export function FetchTags(search = "") {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.get("/employee/tags", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
        params: {
          search, // Include search query as a parameter
        },
      });

      console.log(response);
      dispatch(fetchTagsSuccess(response?.data?.data?.tags));
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to fetch tags.");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// Update Tag
export function UpdateTag(tagData, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.patch(`/employee/tag`, tagData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(updateTagSuccess(response?.data?.data?.tag));
      toast.success("Tag updated successfully!");
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to update tag.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}

// Delete Tag
export function DeleteTagById(tagId, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.delete(`/employee/tag/${tagId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(deleteTagSuccess({ id: tagId }));
      toast.success("Tag deleted successfully!");
    } catch (error) {
      dispatch(setError(error.message || "Failed to delete tag."));
      toast.error(error?.message || "Failed to delete tag.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}
