import { X } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import Checkbox from "../Form/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { FetchTags } from "../../redux/slices/tag";
import { GetConversations } from "../../redux/slices/chat";
import { SetTagFilters } from "../../redux/slices/app";

export default function Filters({ open, handleClose }) {
  const dispatch = useDispatch();
  const modal = useRef(null);

  const { tagFilters } = useSelector((state) => state.app);
  const { isLoading, filter } = useSelector((state) => state.chat);

  // Fetching the tags from Redux store
  const { tags } = useSelector((state) => state.tag);

  useEffect(() => {
    dispatch(FetchTags()); // Dispatch action to fetch tags
  }, [dispatch]);

  // Handle checkbox changes
  const handleCheckboxChange = (tagId) => {
    const newArray = tagFilters?.includes(tagId)
      ? tagFilters?.filter((id) => id !== tagId) // Deselect if already selected
      : [...tagFilters, tagId]; // Add if not selected
    dispatch(SetTagFilters(newArray));
  };

  // Apply filters and log selected tag IDs
  const applyFilters = () => {
    console.log(tagFilters); // Log array of selected tag IDs

    dispatch(GetConversations(tagFilters, filter, handleClose));
  };

  // Clear filters and log deselected tag IDs
  const clearFilters = () => {
    dispatch(SetTagFilters([]));
    console.log([]); // Log empty array

    dispatch(GetConversations([], filter, handleClose));
  };

  // Close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!open || keyCode !== 27) return;
      handleClose();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [open, handleClose]);

  return (
    <div
      className={`fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 ${
        open ? "block" : "hidden"
      }`}
    >
      <div
        ref={modal}
        className="md:px-17.5 w-full max-w-142.5 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-8 space-y-10"
      >
        <div className="flex flex-row items-center justify-between">
          <div className="text-black font-semibold dark:text-white">
            Filter by Tags
          </div>

          <button onClick={handleClose}>
            <X size={20} />
          </button>
        </div>

        <div className="flex flex-col items-start space-y-2">
          {tags.map(({ name, _id, colorCode }) => (
            <div key={_id}>
              <Checkbox
                text={name}
                checked={tagFilters.includes(_id)} // Control checkbox state
                onChange={() => handleCheckboxChange(_id)} // Handle checkbox change
                color={colorCode}
              />
            </div>
          ))}
        </div>

        <div className="flex flex-row items-center justify-between space-x-4">
          <button
            onClick={clearFilters}
            disabled={isLoading}
            className="w-full cursor-pointer rounded-lg border border-danger bg-transparent p-4 text-danger transition hover:bg-opacity-90"
          >
            {isLoading ? (
              <>
                <svg
                  className="mr-2 inline h-4 w-4 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C3.58 0 0 3.58 0 8h4z"
                  ></path>
                </svg>
                Submitting...
              </>
            ) : (
              "Clear"
            )}
          </button>
          <button
            onClick={applyFilters}
            disabled={isLoading}
            className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
          >
            {isLoading ? (
              <>
                <svg
                  className="mr-2 inline h-4 w-4 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C3.58 0 0 3.58 0 8h4z"
                  ></path>
                </svg>
                Submitting...
              </>
            ) : (
              "Apply Filter"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
