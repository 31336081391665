import { File, Image, Paperclip } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { SetBlob, ToggleDocumentModal, ToggleMediaModal } from "../redux/slices/app";

const AttachmentPopover = () => {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <>
      <div className="relative flex">
        <button
          className="hover:text-primary"
          ref={trigger}
          onClick={(e) => {
            e.preventDefault();
            setDropdownOpen(!dropdownOpen);
          }}
        >
          {/* Ícone de Anexo */}
          <Paperclip size={20} />
        </button>
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
          className={`absolute right-0 -top-24 z-40 w-54 space-y-1 rounded-sm border border-stroke bg-white p-1.5 shadow-default dark:border-strokedark dark:bg-boxdark ${
            dropdownOpen === true ? "block" : "hidden"
          }`}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              // TODO => Execute função para abrir o Modal de Seleção de Mídia
              dispatch(SetBlob(null));
              dispatch(ToggleMediaModal(true));
            }}
            className="flex w-full items-center gap-2 rounded-sm px-4 py-1.5 text-left text-sm hover:bg-gray dark:hover:bg-meta-4"
          >
            {/* Ícone de Editar */}
            <Image />
            Imagens & Vídeos
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              // TODO => Execute função para abrir o Modal de Seleção de Documentos
              dispatch(ToggleDocumentModal(true));
            }}
            className="flex w-full items-center gap-2 rounded-sm px-4 py-1.5 text-left text-sm hover:bg-gray dark:hover:bg-meta-4"
          >
            {/* Ícone de Arquivo */}
            <File />
            Arquivo & Documento
          </button>
        </div>
      </div>
    </>
  );
};

export default AttachmentPopover;
