import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  error: null,
  conversations: [],
  users: [],
  currentConversation: null,
  currentParticipant: null,
  currentMessages: [],
  typing: {},
  sendingMsg: false,
  currentUser: {},
  userInfoOpen: false,
  filter: 0,
  totalUnread: 0,
  totalUnassigned: 0,
};

const slice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    updateConversationSuccess(state, action) {
      state.conversations = state.conversations.map((e) => {
        if (e._id === action.payload.conversation._id) {
          return action.payload.conversation;
        } else {
          return e;
        }
      });

      state.currentUser = action.payload.user;
    },
    startConversationSuccess(state, action) {
      const prevConversation = state.conversations.find(
        (el) => el._id === action.payload._id
      );

      if (prevConversation) {
        state.conversations = state.conversations.map((el) => {
          if (el._id === action.payload._id) {
            return action.payload;
          }
          return el;
        });
      } else {
        state.conversations.unshift(action.payload);
      }
    },
    fetchUsersSuccess(state, action) {
      state.users = action.payload;
    },
    fetchTotalUnreadUnassignedSuccess(state, action) {
      state.totalUnassigned = action.payload.totalUnassigned;
      state.totalUnread = action.payload.totalUnread;
    },
    fetchConversationsSuccess(state, action) {
      state.conversations = action.payload;
      // check if currentConversation is inside action.payload then don't change state.currentConversation otherwise set it to null
      if (
        !action.payload.map((el) => el._id).includes(state.currentConversation)
      ) {
        state.currentConversation = null;
        window.localStorage.setItem("current-conversation", null);
      }
      state.currentParticipant = null;
    },
    setCurrentConversation(state, action) {
      state.currentConversation = action.payload;
      window.localStorage.setItem("current-conversation", action.payload);
      // get current participant ID
      const this_conversation = state.conversations.find(
        (el) => el._id === action.payload
      );
      state.userInfoOpen = false;
      state.currentParticipant = this_conversation?.participant?._id;
      state.currentUser = this_conversation?.participant;
    },
    updateUserStatus(state, action) {
      state.conversations = state.conversations.map((conversation) => {
        return {
          ...conversation,
          participants: conversation.participants.map((el) => {
            if (el._id === action.payload.userId) {
              return {
                ...el,
                status: action.payload.status,
              };
            } else {
              return el;
            }
          }),
        };
      });
    },
    replaceChatHistory(state, action) {
      // check if currentParticipant is equal to action.payload.participant
      if (state.currentParticipant === action.payload.participant) {
        state.currentMessages = action.payload.history;
      }
    },
    addMessageSuccess(state, action) {
      // check if currentParticipant is equal to action.payload.participant
      if (state.currentParticipant === action.payload.participant) {
        // add message to currentMessages List
        state.currentMessages.push(action.payload.message);
      }

      // check if state.conversations dosen't have a conversation with participant === action.payload.participant then add a new conversation to the list

      const existingConversation = state.conversations.find(
        (el) => el.participant._id === action.payload.participant
      );

      if (existingConversation) {
        // update LastMessage in conversation from state.conversations that has same participant._id as action.payload.participant
        state.conversations = state.conversations.map((el) => {
          if (el.participant._id === action.payload.participant) {
            if (state.currentParticipant !== action.payload.participant) {
              return {
                ...el,
                lastMsg: action.payload.message,
                updatedAt: Date.now(),
                unread: action.payload.conversation.unread,
                answered: action.payload.conversation.answered,
                assignement: action.payload.conversation.assignment,
              };
            } else {
              return {
                ...el,
                lastMsg: action.payload.message,
                updatedAt: Date.now(),
                answered: action.payload.conversation.answered,
                assignment: action.payload.conversation.assignment,
              };
            }
          } else {
            return el;
          }
        });
      } else {
        state.conversations.unshift(action.payload.conversation);
      }

      state.sendingMsg = false;
    },
    updateTyping(state, action) {
      state.typing = action.payload;
    },
    updateConversationTags(state, action) {
      state.conversations = state.conversations.map((el) => {
        if (el._id === action.payload.conversationId) {
          return {
            ...el,
            tags: action.payload.tags,
          };
        } else {
          return el;
        }
      });
    },
    updateConversationUnreadStatus(state, action) {
      state.conversations = state.conversations.map((el) => {
        if (el._id === action.payload.conversationId) {
          return {
            ...el,
            unread_manual: action.payload.unread_manual,
          };
        } else {
          return el;
        }
      });
    },
    updateMsg(state, action) {
      // check if currentParticipant is equal to action.payload.participant
      if (state.currentParticipant === action.payload.participant) {
        // update message content by finding via messageId currentMessages List
        state.currentMessages = state.currentMessages.map((el) => {
          if (el._id === action.payload.messageId) {
            return {
              ...el,
              content: action.payload.content,
              edited: true,
            };
          } else {
            return el;
          }
        });
      }
    },
    deleteMsgSuccess(state, action) {
      // check if currentParticipant is equal to action.payload.participant
      if (state.currentParticipant === action.payload.participant) {
        // update message active to false by finding via messageId currentMessages List
        state.currentMessages = state.currentMessages.map((el) => {
          if (el._id === action.payload.messageId) {
            return {
              ...el,
              active: false,
            };
          } else {
            return el;
          }
        });
      }
    },
    updateSendingMsgSuccess(state, action) {
      state.sendingMsg = action.payload;
    },
    markAsReadSuccess(state, action) {
      // set unread: 0 in conversation by id in state.conversations
      console.log(action.payload, "mark-read");
      state.conversations = state.conversations.map((el) => {
        if (el._id.toString() === action.payload.toString()) {
          return {
            ...el,
            unread: 0,
            unread_manual: false,
          };
        } else {
          return el;
        }
      });
    },
    fetchCurrentUserSuccess(state, action) {
      state.currentUser = action.payload;
    },
    updateUserInfoOpen(state, action) {
      state.userInfoOpen = action.payload;
    },
    updateFilterSuccess(state, action) {
      state.filter = action.payload;
      state.userInfoOpen = false;
    },
  },
});

export default slice.reducer;

const {
  setError,
  setLoading,
  startConversationSuccess,
  fetchUsersSuccess,
  fetchConversationsSuccess,
  setCurrentConversation,
  updateUserStatus,
  replaceChatHistory,
  addMessageSuccess,
  updateTyping,
  updateConversationTags,
  updateMsg,
  deleteMsgSuccess,
  updateSendingMsgSuccess,
  markAsReadSuccess,
  updateConversationSuccess,
  fetchCurrentUserSuccess,
  updateUserInfoOpen,
  updateFilterSuccess,
  updateConversationUnreadStatus,
  fetchTotalUnreadUnassignedSuccess,
} = slice.actions;

// MARK CONVERSATION AS READ SUCCESS
export function MarkConversationAsRead(id) {
  return async (dispatch, getState) => {
    dispatch(markAsReadSuccess(id));
  };
}

// UPDATE TYPING
export function UpdateTypingStatus(data) {
  return async (dispatch, getState) => {
    dispatch(updateTyping(data));
  };
}

// UPDATE SENDING MSG
export function UpdateSendingMsg(value) {
  return async (dispatch, getState) => {
    dispatch(updateSendingMsgSuccess(value));
  };
}

// ADD MESSAGE
export function AddMessage(data) {
  return async (dispatch, getState) => {
    dispatch(addMessageSuccess(data));
  };
}

// UPDATE MESSAGE
export function UpdateMessageContent(data) {
  return async (dispatch, getState) => {
    dispatch(updateMsg(data));
  };
}

// DELETE MESSAGE
export function DeleteMessageContent(data) {
  return async (dispatch, getState) => {
    dispatch(deleteMsgSuccess(data));
  };
}

// UPDATE STATUS
export function UpdateStatus(data) {
  return async (dispatch, getState) => {
    dispatch(updateUserStatus(data));
  };
}

// UPDATE CHAT HISTORY
export function FetchChatHistory(data) {
  return async (dispatch, getState) => {
    dispatch(replaceChatHistory(data));
  };
}

// GET USERS
export function GetUsers() {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    await axios
      .get("/user/users", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then(function (response) {
        console.log(response);
        dispatch(fetchUsersSuccess(response?.data?.data?.users));
      })
      .catch(function (error) {
        console.log(error);
        dispatch(setError(error));
        toast.error(error?.message || "Something went wrong!");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

// START CONVERSATION
export function StartConversation(formValues) {
  return async (dispatch, getState) => {
    await axios
      .post(
        "/user/start-conversation",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        dispatch(startConversationSuccess(response?.data?.data?.conversation));
        // dispatch(fetchUsersSuccess(response?.data?.data?.users));
      })
      .catch(function (error) {
        console.log(error);
        dispatch(setError(error));
        toast.error(error?.message || "Something went wrong!");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

// GET CONVERSATIONS
export function GetConversations(tags, filter = 0, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    await axios
      .post(
        "/employee/conversations",
        { tags },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
          params: {
            filter: getState().chat.filter,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        dispatch(
          fetchConversationsSuccess(response?.data?.data?.conversations)
        );
        dispatch(
          fetchTotalUnreadUnassignedSuccess({
            ...response?.data?.data,
          })
        );
      })
      .catch(function (error) {
        console.log(error);
        dispatch(setError(error));
        toast.error(error?.message || "Something went wrong!");
      })
      .finally(() => {
        dispatch(setLoading(false));
        handleClose();
      });
  };
}

// SET CONVERSATION
export function SetCurrentConversation(id) {
  return async (dispatch, getState) => {
    dispatch(setCurrentConversation(id));
  };
}

// UPDATE CONVERSATION TAGS
export function UpdateConversationTags(data, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    await axios
      .patch(
        "/employee/conversation/tags",
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        // response.data.data.tags
        dispatch(
          updateConversationTags({
            tags: response.data.data.tags,
            conversationId: data.conversationId,
          })
        );
        toast.success(response.data.message || "Updated Successfully!");
      })
      .catch(function (error) {
        console.log(error);
        dispatch(setError(error));
        toast.error(error?.message || "Something went wrong!");
      })
      .finally(() => {
        dispatch(setLoading(false));
        handleClose();
      });
  };
}

// UPDATE CONVERSATION TAGS
export function UpdateUnreadStatus(data) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    await axios
      .patch(
        "/employee/conversation/mark-unread",
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        dispatch(updateConversationUnreadStatus(data));
        toast.success(response.data.message || "Updated Successfully!");
      })
      .catch(function (error) {
        console.log(error);
        dispatch(setError(error));
        toast.error(error?.message || "Something went wrong!");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

// UPDATE CONVERSATION IPTV CREDENTIALS
export function UpdateIPTVCredentials(data, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));

    await axios
      .patch(
        "/employee/conversation/iptv-credentials",
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        dispatch(updateConversationSuccess(response.data.data));
        toast.success(response.data.message || "Updated Successfully!");
      })
      .catch(function (error) {
        console.log(error);
        dispatch(setError(error));
        toast.error(error?.message || "Something went wrong!");
      })
      .finally(() => {
        dispatch(setLoading(false));
        handleClose();
      });
  };
}

// UPDATE CONVERSATION NOTE
export function UpdateNote(data, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));

    await axios
      .patch(
        "/employee/conversation/note",
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        dispatch(updateConversationSuccess(response.data.data));
        toast.success(response.data.message || "Updated Successfully!");
      })
      .catch(function (error) {
        console.log(error);
        dispatch(setError(error));
        toast.error(error?.message || "Something went wrong!");
      })
      .finally(() => {
        dispatch(setLoading(false));
        handleClose();
      });
  };
}

// UPDATE PLAN DURATION
export function UpdatePlanDuration(data, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));

    await axios
      .patch(
        "/employee/conversation/plan-duration",
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        dispatch(updateConversationSuccess(response.data.data));
        toast.success(response.data.message || "Updated Successfully!");
      })
      .catch(function (error) {
        console.log(error);
        dispatch(setError(error));
        toast.error(error?.message || "Something went wrong!");
      })
      .finally(() => {
        dispatch(setLoading(false));
        handleClose();
      });
  };
}

// GET CURRENT USER
export function FetchCurrentUser(conversationId) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.get("/employee/conversation/user", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
        params: {
          conversationId, // Include conversationId as a parameter
        },
      });

      console.log(response);
      dispatch(fetchCurrentUserSuccess(response?.data?.data?.user));
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to fetch current user.");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// UPDATE USER INFO
export function UpdateUserInfo(value) {
  return async (dispatch, getState) => {
    dispatch(updateUserInfoOpen(value));
  };
}

// UPDATE FILTER CATEGORY
export function UpdateFilterCategory(value) {
  return async (dispatch, getState) => {
    dispatch(updateFilterSuccess(value));
  };
}
