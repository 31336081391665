import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://bagdouruwaverxwdymqb.supabase.co';
const supabaseKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJhZ2RvdXJ1d2F2ZXJ4d2R5bXFiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjg1MDA5NjUsImV4cCI6MjA0NDA3Njk2NX0.3uW8euKlJUE_wi5S4DOfOu-ZDlGGxIIILTaDitzcu7s';
const supabase = createClient(supabaseUrl, supabaseKey);

export const uploadToSupabase = async (file) => {
  const fileName = `${Date.now()}_${file.name}`;

  // Determine the MIME type of the file
  const contentType = file?.type || 'application/octet-stream'; // Default to binary stream if no type is found

  const { data, error } = await supabase.storage
    .from('apicechat')
    .upload(fileName, file, {
      upsert: false,
      cacheControl: '3600',
      contentType, // Set content type explicitly
    });

  if (error) {
    throw new Error('Error uploading file: ' + error.message);
  }

  const urlData = supabase.storage.from('apicechat').getPublicUrl(fileName);

  return urlData?.data?.publicUrl; // Return the public URL of the uploaded file
};