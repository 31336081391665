import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  error: null,
  responses: [],
};

const slice = createSlice({
  name: "response",
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    fetchResponsesSuccess(state, action) {
      state.responses = action.payload;
    },
    updateResponseSuccess(state, action) {
      state.responses = state.responses.map((response) => {
        if (response._id === action.payload._id) {
          return action.payload;
        } else {
          return response;
        }
      });
    },
    deleteResponseSuccess(state, action) {
      state.responses = state.responses.filter(
        (response) => response._id !== action.payload.id
      );
    },
    addResponseSuccess(state, action) {
      state.responses.unshift(action.payload);
    },
  },
});

export default slice.reducer;

const {
  setError,
  setLoading,
  addResponseSuccess,
  deleteResponseSuccess,
  fetchResponsesSuccess,
  updateResponseSuccess,
} = slice.actions;

// CreateResponse
export function CreateResponse(data, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.post("/employee/canned-response", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(addResponseSuccess(response?.data?.data?.cannedResponse));
      toast.success("Response created successfully!");
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to create response.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}

// Get Responses
export function FetchResponses(search = "") {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.get("/employee/canned-responses", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
        params: {
          search, // Include search query as a parameter
        },
      });
      console.log(response);
      dispatch(fetchResponsesSuccess(response?.data?.data?.cannedResponses));
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to fetch responses.");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// Update Response
export function UpdateResponse(data, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.patch(`/employee/canned-response`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(updateResponseSuccess(response?.data?.data?.cannedResponse));
      toast.success("Response updated successfully!");
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to update response.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}

// Delete Response
export function DeleteResponseById(id, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.delete(`/employee/canned-response/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(deleteResponseSuccess({ id: id }));
      toast.success("Response deleted successfully!");
    } catch (error) {
      dispatch(setError(error.message || "Failed to delete response."));
      toast.error(error?.message || "Failed to delete response.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}
