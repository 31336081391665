import { X } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { UpdateTag } from "../../redux/slices/tag";

// color picker
import ColorPicker from "@rc-component/color-picker";
import "@rc-component/color-picker/assets/index.css";

// Define the validation schema
const schema = yup.object().shape({
  name: yup
    .string()
    .required("Tag name is required")
    .min(2, "Tag name must be at least 2 characters"),
});

export default function EditTag({ id, open, handleClose }) {
  const modal = useRef(null);

  const dispatch = useDispatch();

  const { tags, isLoading } = useSelector((state) => state.tag);

  const this_tag = tags.find((tag) => tag._id === id);

  const defaultValues = {
    name: this_tag?.name,
  };

  const [color, setColor] = useState(this_tag?.colorCode);

  // Form setup with react-hook-form and yup validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  // Close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!open || keyCode !== 27) return;
      handleClose();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [open, handleClose]);

  // Handle form submission
  const onSubmit = (data) => {
    console.log("Submitted data:", data);
    dispatch(UpdateTag({ ...data, tagId: id, colorCode: color }, handleClose));
    // handleClose();
    // reset(); // Reset form after submission
  };

  return (
    <div
      className={`fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 ${
        open ? "block" : "hidden"
      }`}
    >
      <div
        ref={modal}
        className="w-full max-w-142.5 space-y-10 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:px-17.5 md:py-8"
      >
        <div className="flex flex-row items-center justify-between">
          <div className="font-semibold text-black dark:text-white">
            Edit Tag
          </div>
          <button onClick={handleClose}>
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Enter tag name"
                {...register("name")}
                className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
              {errors.name && (
                <p className="text-red-600 mt-2 text-sm">
                  {errors.name.message}
                </p>
              )}
            </div>

            <div className="w-full flex flex-col items-start mt-4">
              <label htmlFor="" className="text-start">
                Select tag color
              </label>
              <div
                className="w-full border dark:border-strokedark py-4 my-2 rounded-md"
                style={{ backgroundColor: color }}
              ></div>

              <ColorPicker
                value={color}
                defaultValue={color}
                onChange={(e) => {
                  setColor(e.toHexString());
                  console.log(e.toHexString());
                }}
                onChangeComplete={(e) => {
                  setColor(e.toHexString());
                  console.log(e.toHexString());
                }}
              />
            </div>
          </div>

          <div className="mb-5">
            <button
              type="submit"
              className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <svg
                    className="mr-2 inline h-4 w-4 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C3.58 0 0 3.58 0 8h4z"
                    ></path>
                  </svg>
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
