import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

import { Provider as ReduxProvider } from "react-redux";
import { store } from "./redux/store";

import "yet-another-react-lightbox/styles.css";
import "dropzone/dist/dropzone.css";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://79a53e79fd579a153bd134c03343c686@o4508453960024064.ingest.us.sentry.io/4508453962711040",
  integrations: [],
});

createRoot(document.getElementById("root")).render(
  // <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <App />
        <ToastContainer position="top-right" autoClose={5000} stacked />
      </BrowserRouter>
    </ReduxProvider>
  // </StrictMode>
);
