import { createSlice } from "@reduxjs/toolkit";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  modals: {
    audio: false,
    media: false,
    document: false,
    gif: false,
    cannedMedia: {
      open: false,
      content: "",
      url: "",
    },
  },
  msgId: "",
  selectedGifUrl: "",
  tagFilters: [], // _id of tags by which we need to filter conversations
  blob: null,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateAudioModal(state, action) {
      state.modals.audio = action.payload;
    },
    updateMediaModal(state, action) {
      state.modals.media = action.payload;
    },
    updateDocumentModal(state, action) {
      state.modals.document = action.payload;
    },
    updateGifModal(state, action) {
      state.modals.gif = action.payload.value;
      state.selectedGifUrl = action.payload.url;
    },
    updateTagFiltersSuccess(state, action) {
      state.tagFilters = action.payload;
    },
    updateCannedMediaModalSuccess(state, action) {
      state.modals.cannedMedia.open = action.payload.open;
      state.modals.cannedMedia.content = action.payload.content;
      state.modals.cannedMedia.url = action.payload.url;
    },
    setMsgIdSuccess(state, action) {
      state.msgId = action.payload;
    },
    updateBlobSuccess(state, action) {
      state.blob = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const SetTagFilters = (value) => async (dispatch, getState) => {
  dispatch(slice.actions.updateTagFiltersSuccess(value));
};
export const ToggleAudioModal = (value) => async (dispatch, getState) => {
  dispatch(slice.actions.updateAudioModal(value));
};
export const ToggleMediaModal = (value) => async (dispatch, getState) => {
  dispatch(slice.actions.updateMediaModal(value));
};
export const ToggleDocumentModal = (value) => async (dispatch, getState) => {
  dispatch(slice.actions.updateDocumentModal(value));
};
export const ToggleGifModal = (value) => async (dispatch, getState) => {
  dispatch(slice.actions.updateGifModal(value));
};
export const ToggleCannedMediaModal = (data) => async (dispatch, getState) => {
  dispatch(slice.actions.updateCannedMediaModalSuccess(data));
};
export const SetMsgId = (id) => async (dispatch, getState) => {
  dispatch(slice.actions.setMsgIdSuccess(id));
};
export const SetBlob = (value) => async (dispatch, getState) => {
  dispatch(slice.actions.updateBlobSuccess(value));
}