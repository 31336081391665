import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Messages from "./pages/Messages";
import Login from "./pages/auth/Login";
import Layout from "./layout";
import Profile from "./pages/ProfilePage";
import SettingsPage from "./pages/Settings";

export default function App() {
  useEffect(() => {
    // const colorMode = JSON.parse(window.localStorage.getItem("color-theme"));

    const colorMode = 'dark';

    const className = "dark";
    const bodyClass = window.document.body.classList;

    colorMode === "dark"
      ? bodyClass.add(className)
      : bodyClass.remove(className);
  }, []);

  return (
    <Routes>
      {/* Redirect / to /auth/login */}
      <Route path="/" element={<Navigate to="/auth/login" />} />

      {/* Auth Routes */}
      <Route path="/auth/login" element={<Login />} />

      {/* Dashboard Routes (Requires Layout) */}
      <Route path="/dashboard" element={<Layout />}>
        <Route index element={<Messages />} />
        <Route path="profile" element={<Profile />} />
        <Route path="settings" element={<SettingsPage />} />
      </Route>
    </Routes>
  );
}
