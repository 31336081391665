import { X } from '@phosphor-icons/react';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { uploadToSupabase } from '../../utils/supabase'; // Update the path to your supabase utility file
import { useDispatch, useSelector } from 'react-redux';

import { CreateResponse } from '../../redux/slices/response';

// Validation schema
const schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  content: yup.string().required('Response is required'),
});

export default function AddResponse({ open, handleClose }) {
  const dispatch = useDispatch();
  const modal = useRef(null);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const { isLoading } = useSelector((state) => state.response);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    let fileUrl;
    try {
      if (file?.[0]) {
        const uploadedUrl = await uploadToSupabase(file[0]);
        fileUrl = uploadedUrl;
        console.log(uploadedUrl);
        setFileUrl(uploadedUrl);
      }
      // Proceed with further form submission logic, like sending data to your API
      console.log('Form data:', { ...data, image: fileUrl });
      // reset();
      // handleClose();
      dispatch(CreateResponse({ ...data, image: fileUrl }, handleClose));
    } catch (error) {
      console.error('Error uploading file:', error.message);
    }
  };

  // Close modal if the Esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (keyCode === 27) handleClose();
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [handleClose]);

  return (
    <div
      className={`fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 ${
        open ? 'block' : 'hidden'
      }`}
    >
      <div
        ref={modal}
        className="w-full max-w-142.5 space-y-10 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:px-17.5 md:py-8"
      >
        <div className="flex flex-row items-center justify-between">
          <div className="text-black dark:text-white">Add Canned Response</div>
          <button onClick={handleClose}>
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label className="mb-2.5 block text-start font-medium dark:text-white">
              Title
            </label>
            <input
              type="text"
              placeholder="Enter title"
              className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              {...register('title')}
            />
            {errors.title && (
              <p className="text-start text-sm text-red">
                {errors.title.message}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label className="mb-2.5 block text-start font-medium dark:text-white">
              Response
            </label>
            <textarea
              placeholder="Enter Response"
              className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              {...register('content')}
            />
            {errors.content && (
              <p className="text-start text-sm text-red">
                {errors.content.message}
              </p>
            )}
          </div>

          <div className="mb-5">
            <label
              htmlFor="taskImg"
              className="mb-2.5 block text-start font-medium dark:text-white"
            >
              Add image
            </label>
            <input
              type="file"
              accept="image/*"
              className="block w-full cursor-pointer rounded-sm border border-dashed border-stroke bg-white px-4 py-4 dark:border-strokedark dark:bg-boxdark"
              onChange={(e) => setFile(e.target.files)}
            />
            {errors.file && (
              <p className="text-start text-sm text-red">
                {errors.file.message}
              </p>
            )}
            {fileUrl && (
              <p className="mt-2 text-start text-sm text-green-500">
                File uploaded successfully!
              </p>
            )}
          </div>

          <div className="mb-5">
            <button
              disabled={isLoading || isSubmitting}
              type="submit"
              className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
            >
              {isSubmitting || isLoading ? (
                <>
                  <svg
                    className="mr-2 inline h-4 w-4 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C3.58 0 0 3.58 0 8h4z"
                    ></path>
                  </svg>
                  Submitting...
                </>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
