import { Minus, Plus, TagChevron, X } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchTags } from "../../redux/slices/tag";
import { UpdateConversationTags } from "../../redux/slices/chat";

const TAGS = [
  {
    key: 0,
    title: "New Client",
    linked: false,
  },
  {
    key: 1,
    title: "Trial",
    linked: true,
  },
  {
    key: 2,
    title: "Premium",
    linked: true,
  },
  {
    key: 3,
    title: "Demo",
    linked: true,
  },
];

export default function TagUser({ open, handleClose }) {
  const modal = useRef(null);
  const dispatch = useDispatch();

  const tagState = useSelector((state) => state.tag);

  const isTagLoading = tagState?.isLoading;
  const tags = tagState?.tags;

  const { conversations, currentConversation, isLoading } = useSelector(
    (state) => state.chat
  );

  const this_conversation = conversations.find(
    (el) => el._id === currentConversation
  );

  const linkedTags = this_conversation?.tags.map((el) => el._id);

  const LIST = tags.map((tag) => ({
    key: tag?._id,
    title: tag?.name,
    linked: linkedTags?.includes(tag._id),
    color: tag?.colorCode,
  }));

  const [list, setList] = useState(LIST);

  useEffect(() => {
    dispatch(FetchTags());
  }, []);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      handleClose();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const handleApplyChange = () => {
    const data = {
      conversationId: currentConversation,
      include: list.filter((el) => el.linked).map((el) => el.key),
      exclude: list.filter((el) => !el.linked).map((el) => el.key),
    };

    dispatch(UpdateConversationTags(data, handleClose));
  };

  return (
    <div
      className={`fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 ${
        open ? "block" : "hidden"
      }`}
    >
      <div
        ref={modal}
        className="md:px-17.5 w-full max-w-142.5 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-8 space-y-10"
      >
        <div className="flex flex-row items-center justify-between">
          <div className="text-black dark:text-white font-semibold">
            Manage User Tags
          </div>

          <button onClick={handleClose}>
            <X size={20} />
          </button>
        </div>
        <div className="flex flex-row items-center gap-4 flex-wrap">
          {isTagLoading ? (
            <div className="w-full flex flex-row items-center justify-center">
              <div className="text-lg">Loading Tags...</div>
            </div>
          ) : list.length === 0 ? (
            <div className="w-full flex flex-row items-center justify-center">
              <div className="text-lg">No Tags Found</div>
            </div>
          ) : (
            list.map(({ key, linked, title, color }) => (
              <div
                key={key}
                onClick={() => {
                  setList((p) => {
                    return p.map((el) => {
                      if (el.key === key) {
                        return {
                          ...el,
                          linked: !el.linked,
                        };
                      }
                      return el;
                    });
                  });
                }}
                className={`border px-4 py-1.5 rounded-full flex flex-row items-center hover:cursor-pointer ${
                  linked
                    ? "bg-primary border-primary text-white"
                    : "border-stroke dark:border-strokedark"
                }`}
              >
                 <TagChevron weight="fill" className="mr-2" color={color} />
                {title}
                {linked ? (
                  <button className="ml-2">
                    <Minus size={24} />
                  </button>
                ) : (
                  <button className="ml-2">
                    <Plus size={24} />
                  </button>
                )}
              </div>
            ))
          )}
        </div>
        <button
          onClick={handleApplyChange}
          className="w-full cursor-pointer rounded-lg border bg-transparent border-primary p-4 text-primary transition hover:bg-opacity-90"
        >
          {isLoading ? (
            <>
              <svg
                className="mr-2 inline h-4 w-4 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C3.58 0 0 3.58 0 8h4z"
                ></path>
              </svg>
              Submitting...
            </>
          ) : (
            "Apply Changes"
          )}
        </button>
      </div>
    </div>
  );
}
