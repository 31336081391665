import React from "react";
import { ChatTeardropText } from "@phosphor-icons/react";
import Logo from "./../assets/logo.png";

export default function () {
  return (
    <div className="flex flex-row items-center space-x-2">
      <img src={Logo} className="h-20" />
      <div className="text-2xl font-medium text-body dark:text-white">
        Apicechat
      </div>
    </div>
  );
}
