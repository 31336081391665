import React from "react";
import { Eye, EyeSlash, Star } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUnreadStatus } from "../../redux/slices/chat";

export default function Header({ handleToggleUserInfo }) {
  const dispatch = useDispatch();
  const { conversations, currentConversation, currentUser } = useSelector(
    (state) => state.chat
  );

  const this_conversation = conversations.find(
    (el) => el._id === currentConversation
  );

  const name =
    this_conversation?.participant?.name ||
    this_conversation?.participant?.username;

  return (
    <>
      <div className="sticky flex items-center justify-between border-b border-stroke px-6 py-3 dark:border-strokedark">
        <div className="flex items-center">
          <div
            onClick={handleToggleUserInfo}
            className="mr-4.5 h-13 w-full max-w-13 overflow-hidden rounded-full"
          >
            <div className="h-13 w-13 rounded-full border border-stroke dark:border-strokedark bg-boxdark-2 flex items-center justify-center uppercase">
              {name && name[0]}
            </div>
          </div>
          <div>
            <h5 className="font-medium text-black text-nowrap dark:text-white">
              {name}
            </h5>
            {/* // TODO: show online & offline status */}
            {/* <p className="text-sm">Reply to message</p> */}
          </div>
        </div>
        <div className="flex flex-row items-center space-x-9">
          <span className="text-md text-primary">
            Validity: {currentUser?.planDuration} Days
          </span>
          {
            <button
              onClick={() => {
                const data = {
                  conversationId: currentConversation,
                  unread_manual: !this_conversation.unread_manual,
                };
                dispatch(UpdateUnreadStatus(data));
              }}
              className={`flex flex-row space-x-3 items-center`}
            >
              {this_conversation?.unread_manual ? <Eye size={20} /> : <EyeSlash size={20} />}
              <span className="">{this_conversation?.unread_manual ? "Mark as read" : "Mark as unread"}</span>
            </button>
          }
        </div>
      </div>
    </>
  );
}
