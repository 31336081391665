import { TagChevron } from "@phosphor-icons/react";
import React from "react";

export default function Checkbox({ text, checked, onChange, color }) {
  return (
    <label className="inline-flex items-start space-x-2">
      <input
        type="checkbox"
        checked={checked} // Control checked state from parent
        onChange={onChange} // Trigger parent change handler
        className="form-checkbox h-5 w-5"
      />
      <div className="flex flex-row items-center space-x-2">
        <span>{text}</span>
        <TagChevron weight="fill" color={color} />
      </div>
    </label>
  );
}
