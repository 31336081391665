import React, { useEffect, useState } from "react";

import {
  Chat,
  Gear,
  SignOut,
  UserCircle,
} from "@phosphor-icons/react";
import DarkModeSwitcher from "../components/DarkModeSwitcher";
import { useNavigate } from "react-router-dom";

import LogoImg from "../assets/logo.png";
import { useDispatch } from "react-redux";
import { LogoutUser } from "../redux/slices/auth";
import { GetMe } from "../redux/slices/user";

const NAVIGATION = [
  {
    key: 0,
    title: "DMs",
    icon: <Chat size={24} />,
    path: "/dashboard",
  },
  {
    key: 1,
    title: "Profile",
    icon: <UserCircle size={24} />,
    path: "/dashboard/profile",
  },
  {
    key: 2,
    title: "Settings",
    icon: <Gear size={24} />,
    path: "/dashboard/settings",
  },
];

export default function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);

  const handleClick = (key) => {
    navigate(NAVIGATION[key].path);
    setSelected(key);
  };

  useEffect(() => {
    // Check the current URL and set the initial selected value
    const currentPath = location.pathname;
    const currentNav = NAVIGATION.find((item) => item.path === currentPath);
    if (currentNav) {
      setSelected(currentNav.key);
    }
    dispatch(GetMe());
  }, [location, dispatch]);

  return (
    <div className="flex flex-col border-r border-stroke p-2 dark:border-strokedark dark:bg-boxdark-2">
      <div className="space-y-5 flex flex-col items-center">
        <img src={LogoImg} alt="" className="h-15" />

        {NAVIGATION.map(({ icon, key, title }) => (
          <div
            key={key}
            className="space-y-2 flex flex-col text-center hover:cursor-pointer hover:text-primary"
            onClick={() => handleClick(key)}
          >
            <div
              className={`mx-auto rounded-md border border-stroke p-2 dark:border-strokedark ${
                selected === key && "bg-primary bg-opacity-90 text-white"
              } hover:border-primary  dark:hover:border-primary`}
            >
              {icon}
            </div>
            <span
              className={`font-medium text-sm ${
                selected === key && "text-primary"
              }`}
            >
              {title}
            </span>
          </div>
        ))}
      </div>

      <div className="flex grow flex-col"></div>
      <div className="space-y-4">
        {/* <DarkModeSwitcher /> */}
        <div
          onClick={() => {
            dispatch(LogoutUser(navigate));
          }}
          className="flex flex-row items-center justify-center rounded-md border border-stroke p-2 dark:border-strokedark"
        >
          <SignOut size={24} />
        </div>
      </div>
    </div>
  );
}
