import React, { useEffect } from "react";
import { ChatList, Inbox } from "../sections/chat";
import MediaPicker from "../components/MediaPicker";
import DocumentPicker from "../components/DocumentPicker";
import AudioRecorderModal from "../components/AudioRecorder";
import GifModal from "../components/GifModal";
import { useDispatch, useSelector } from "react-redux";
import { FetchResponses } from "../redux/slices/response";
import CannedMediaModal from "../components/CannedMediaModal";

const Messages = () => {
  const dispatch = useDispatch();

  const { audio, media, gif, document } = useSelector(
    (state) => state.app.modals
  );

  useEffect(() => {
    dispatch(FetchResponses());
  }, []);

  return (
    <>
      {/* Main Content */}
      <div className="flex w-full">
        {/* ChatList */}
        <ChatList />
        {/* Inbox */}
        <Inbox />
      </div>

      {/* Modals */}
      <MediaPicker modalOpen={media} />
      <DocumentPicker modalOpen={document} />
      <AudioRecorderModal modalOpen={audio} />
      <GifModal modalOpen={gif} />
      <CannedMediaModal />
    </>
  );
};

export default Messages;
