import axios from "axios";

// CONFIGURE BASE URL

// const BASE_URL = "http://localhost:8000";

const BASE_URL = "https://api.bluemeet.in";

const axiosInstance = axios.create({ baseURL: BASE_URL });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
export { BASE_URL };
