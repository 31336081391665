import { X } from '@phosphor-icons/react';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteResponseById } from '../../redux/slices/response';

export default function DeleteResponse({ id, open, handleClose }) {
  const modal = useRef(null);

  const dispatch = useDispatch();

  const { responses, isLoading } = useSelector((state) => state.response);

  const this_response = responses.find((response) => response._id === id);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      handleClose();
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const handleDelete = () => {
    dispatch(DeleteResponseById(id, handleClose));
  };

  return (
    <div
      className={`fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 ${
        open ? 'block' : 'hidden'
      }`}
    >
      <div
        ref={modal}
        className="w-full max-w-142.5 space-y-10 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:px-17.5 md:py-8"
      >
        <div className="flex flex-row items-center justify-between">
          <div className="text-black dark:text-white">
            Are you sure to delete response ?
          </div>

          <button onClick={handleClose}>
            <X size={20} />
          </button>
        </div>

        <div>
          <div className="mb-4 space-y-2 text-start">
            <div className="mb-2.5 block text-start font-medium dark:text-white">
              Title
            </div>
            <span className="text-[#637381] dark:text-bodydark">
              {this_response?.title}
            </span>
          </div>
          <div className="mb-4 space-y-2 text-start">
            <div className="mb-2.5 block text-start font-medium dark:text-white">
              Response
            </div>
            <span className="text-[#637381] dark:text-bodydark">
              {this_response?.content}
            </span>
          </div>

          <div className="mb-4 space-y-2 text-start">
            <div className="mb-2.5 block text-start font-medium dark:text-white">
              Image
            </div>
            <img
              src={this_response?.image}
              className="h-40 w-full object-cover object-center"
            />
          </div>

          <div className="flex flex-row items-center justify-between space-x-4">
            <button
              onClick={handleClose}
              className="w-full cursor-pointer rounded-lg border border-danger bg-transparent p-4 text-danger transition hover:bg-opacity-90"
            >
              Cancel
            </button>
            <button
              disabled={isLoading}
              onClick={handleDelete}
              className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
            >
              {isLoading ? (
                <>
                  <svg
                    className="mr-2 inline h-4 w-4 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C3.58 0 0 3.58 0 8h4z"
                    ></path>
                  </svg>
                  Submitting...
                </>
              ) : (
                'Delete'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
