import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  error: null,
  token: null,
  user: {},
  user_id: null,
  isLoggedIn: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    loginSuccess(state, action) {
      state.isLoggedIn = true;
      window.localStorage.setItem("current-conversation", null);
      state.user_id = action.payload.user_id;
      state.token = action.payload.token;
    },
    logoutSuccess(state, action) {
      window.localStorage.setItem("current-conversation", null);
      state.isLoggedIn = false;
      state.token = null;
    },
  },
});

export default slice.reducer;

const { setError, setLoading, loginSuccess, logoutSuccess } = slice.actions;

// ** LOGOUT USER
export function LogoutUser(navigate) {
  return async (dispatch, getState) => {
    try {
      dispatch(logoutSuccess({}));
      navigate("/");
      toast.success("Logged out successfully!");
    } catch (error) {
      console.log(error);
    }
  };
}

// ** LOGIN USER
export function LoginUser(formData, navigate) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));

    const reqBody = {
      ...formData,
    };

    // MAKE API CALL
    await axios
      .post("/auth/login", reqBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response.data);

        const { token, message, user_id } = response.data;

        dispatch(loginSuccess({ token, user_id }));

        toast.success(message || "Logged in successfully!");
      })
      .catch(function (error) {
        console.log(error);
        dispatch(setError(error));
        toast.error(error?.message || "Something went wrong!");
      })
      .finally(() => {
        dispatch(setLoading(false));
        //  do navigation logic over here
        if (!getState().auth.error) {
          navigate(`/dashboard`);
        }
      });
  };
}
